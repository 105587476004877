import React, { useRef, useState, useEffect } from "react"
// import * as Yup from "yup"
import axios from "axios"

import { locations } from "src/evag"
import Select from "src/components/form/Select"
import FieldsToComps from "src/components/FieldsToComps"

const ufList = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MG",
  "MS",
  "MT",
  "PA",
  "PB",
  "PE",
  "PI",
  "PR",
  "RJ",
  "RN",
  "RO",
  "RR",
  "RS",
  "SC",
  "SE",
  "SP",
  "TO",
]

let cityCache = []

function FormAddress(props) {
  let { values, handleChange, handleBlur, setFieldValue } = props
  const numero = useRef(null)
  const logradouro = useRef(null)

  const [disabledLogradouro, setDisabledLogradouro] = useState(false)
  const [cityList, setCityList] = useState([])
  const [loadingCities, setLoadingCities] = useState(false)

  useEffect(() => {
    const uf = values["uf"]
    if (uf) {
      setCityList(cityCache)
    }
  }, [values])

  function cepHandleChange(setFieldValue, handleChange) {
    return event => {
      const numeric = event.target.value.replace(/[^0-9]/, "")
      if (numeric.length === 8) {
        setDisabledLogradouro(true)

        let focus = true
        axios
          .get(`https://viacep.com.br/ws/${numeric}/json/`)
          .then(res => {
            if ("data" in res && "logradouro" in res.data) {
              reloadCities(res.data.uf)
              setFieldValue("endLogradouro", res.data.logradouro)
              setFieldValue("district", res.data.bairro)
              setFieldValue("uf", res.data.uf)
              setFieldValue("city", res.data.localidade)
              numero.current.focus()
              focus = false
            }
          })
          .catch(error => {
            console.error(error)
          })
          .then(() => {
            setDisabledLogradouro(false)
            if (focus) {
              logradouro.current.focus()
            }
          })
      }

      handleChange(event)
    }
  }

  function reloadCities(uf) {
    setCityList([])
    setLoadingCities(true)

    locations("BR", uf)
      .then(res => {
        cityCache = res.data.map(e => (typeof e === "string" ? e : e.name))
        setCityList(cityCache)
        setLoadingCities(false)
      })
      .catch(err => {
        console.error(err)

        setLoadingCities(false)

        setFieldValue("uf", "")
        setFieldValue("city", "")
      })
  }

  function ufHandleChange(event) {
    setFieldValue("city", "")
    reloadCities(event.target.value)
    handleChange(event)
  }

  let fields = FormAddress.fields({
    props,
    state: { disabledLogradouro, cityList, loadingCities },
    cepHandleChange,
    numero,
    logradouro,
    ufHandleChange,
  })
  return (
    <FieldsToComps
      {...{
        fields,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
      }}
    />
  )
}

// This function maybe called without this/self, just to get the static data (like field names).
// That's why the default value for self must be a mock.
FormAddress.fields = function(
  self = { props: {}, state: {}, cepHandleChange: () => null }
) {
  return [
    {
      name: "endCEP",
      label: "fields.address.postalCode",
      cleave: { blocks: [5, 3], delimiter: "-", numericOnly: true },
      onChange: self.cepHandleChange(
        self.props.setFieldValue,
        self.props.handleChange
      ),
    },
    {
      name: "uf",
      label: "fields.address.state",
      options: ufList,
      onChange: self.ufHandleChange,
      extra: { widget: Select, required: true },
    },
    {
      name: "city",
      label: "fields.address.city",
      options: self.state.cityList,
      onChange: self.handleChange,
      loaderColor: self.props.loaderColor,
      loading: self.state.loadingCities,
      extra: { widget: Select, required: true },
    },
    {
      name: "district",
      label: "fields.address.district",
      disabled: self.state.disabledLogradouro,
    },
    {
      name: "endLogradouro",
      label: "fields.address.address",
      disabled: self.state.disabledLogradouro,
      ref: self.logradouro,
      extra: { required: true },
    },
    {
      name: "endNumero",
      label: "fields.address.number",
      type: "number",
      ref: self.numero,
    },
    {
      name: "endComplemento",
      label: "fields.address.complement",
    },
  ]
}

export default FormAddress
