const fields = {}
;[
  {
    name: "email",
    label: "fields.email.label",
    type: "email",
    extra: {
      validation: ({ shape }) => shape.email("Digite um e-mail válido."),
      required: true,
    },
  },
  {
    name: "name",
    label: "fields.name.label",
    extra: { required: true },
  },
  {
    name: "gender",
    label: "fields.gender.label",
    options: ["fields.gender.fem", "fields.gender.masc", "fields.gender.other"],
    extra: { required: true },
  },
  {
    name: "phone",
    label: "fields.phone.label",
    type: "tel",
    cleave: {
      blocks: [0, 2, 9],
      delimiters: ["(", ") "],
      numericOnly: true,
    },
    extra: { required: true },
  },
  {
    name: "alt_phone",
    label: "fields.alt_phone.label",
    type: "tel",
    cleave: {
      blocks: [0, 2, 9],
      delimiters: ["(", ") "],
      numericOnly: true,
    },
  },
].forEach(f => (fields[f.name] = f))

export default fields
