import { graphql, useStaticQuery } from "gatsby"

export default () =>
  useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            url
            channelPt
            channelOther
          }
        }
      }
    `
  ).site.siteMetadata
