import React from "react"
import PropTypes from "prop-types"
import { ErrorMessage } from "formik"
import Loader from "react-loader-spinner"
import { useTranslation } from "react-i18next"

import "src/i18n"
import s from "./form.module.scss"

const Select = ({
  name,
  className,
  loaderColor,
  loading,
  options,
  placeholder,
  label,
  value,
  onBlur,
  onChange,
  ...props
}) => {
  const { t } = useTranslation()
  let cls = s.container + (className ? ` ${className}` : "")

  if (loading) {
    return (
      <span className={cls + ` ${s.loading}`} style={props.style}>
        <span className={s.spinner}>
          <Loader type="TailSpin" color={loaderColor} height={13} width={13} />
        </span>
        {t("loading")}...
      </span>
    )
  }

  return (
    <div className={cls} {...props}>
      <label>
        {t(label)}
        <select
          className={s.select}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        >
          <option className={s.placeholder} value="">
            {placeholder}
          </option>
          {options.map((v, i) => (
            <option key={i} value={v}>
              {t(v)}
            </option>
          ))}
        </select>
        <ErrorMessage name={name} component="div" className={s.error} />
      </label>
    </div>
  )
}

Select.defaultProps = {
  className: "",
  loading: false,
  options: [],
  style: {},
  value: "",
}

Select.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  loaderColor: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
}

export default Select
