import React from "react"
import * as Yup from "yup"
import Select from "src/components/form/Select"
import InputText from "src/components/form/InputText"
import InputAddress from "src/components/form/InputAddress"
import InputCheckbox from "src/components/form/InputCheckbox"
import i18n from "src/i18n"

const reqMsg = "Esse campo é obrigatório."

// Some fields may be a group of fields instead of a single field.
// These special fields must have a "fields" function that returns its list of fields.
// The expandStatic function below converts this tree of widgets in a single list of fields.
// The returned list won't have components fields, so it must not be used with FieldsToComps.
export function expandStatic(fields) {
  let exp = []
  for (let f of fields) {
    if (f.extra && f.extra.widget && f.extra.widget.fields) {
      for (let sf of f.extra.widget.fields()) {
        if (f.extra.condition) {
          if (!sf.extra) sf.extra = {}
          if (!sf.extra.condition) sf.extra.condition = f.extra.condition
        }
        exp.push(sf)
      }
    } else {
      exp.push(f)
    }
  }
  return exp
}

// Generates an object with initial values for a list of fields.
export function fieldsToInitVals(fields) {
  return expandStatic(fields).reduce((values, { name }) => {
    values[name] = ""
    return values
  }, {})
}

// Generates a schema for a list of fields.
export function fieldsToSchema(fields) {
  return Yup.object().shape(
    expandStatic(fields).reduce((shapes, { name, extra = {} }) => {
      var s = Yup.string()
      if (extra.widget === InputCheckbox) {
        s = Yup.bool()
      }

      if (extra.condition) {
        s = s.when("$values", (values, s) =>
          extra.condition({ values }) ? s.required(reqMsg) : s
        )
      } else if (extra.required) {
        s = s.required(reqMsg)
      }

      if (extra.validation) {
        s = extra.validation({ shape: s })
      }

      shapes[name] = s
      return shapes
    }, {})
  )
}

// Attempts to fill an entire form with dummy data.
function fillFields(
  fields,
  setFieldValue,
  defaultVal = "11111111",
  emailVal = "a@a.com",
  boolVal = true
) {
  for (let f of expandStatic(fields)) {
    let el = document.getElementsByName(f.name)[0]
    if (el) {
      let v = defaultVal
      if (el.options && el.options.length > 2) {
        v = el.options[1].value
      } else if (f.name === "email") {
        v = emailVal
      } else if (f.extra && f.extra.widget === InputCheckbox) {
        v = boolVal
      }
      setFieldValue(el.name, v)
    }
  }
}

// Converts a fields list to a JSON channel specification.
function fieldsToJSON(fields, formName) {
  // Type for a field.
  function fieldType(f) {
    let t = "string"
    if (f.extra && f.extra.type) {
      t = f.extra.type
    } else if (f.type === "tel") {
      t = "phone"
    } else if (f.type === "number") {
      t = "float"
    } else if (f.extra && f.extra.widget === InputCheckbox) {
      t = "bool"
    }
    return t
  }

  // Validations for a field.
  function fieldVal(f) {
    let v = []

    let val = {
      endCEP: "cep",
      email: "email",
      uf: "uf",
      city: "city",
    }[f.name]
    if (val) {
      v.push(val)
    }

    if (f.extra && f.extra.required) {
      v.push("required")
    }

    return v
  }

  return {
    name: formName,
    projectId: "<projectId>",
    fields: expandStatic(fields).map((f) => ({
      name: f.name,
      label: i18n.t(f.label),
      type: fieldType(f),
      validation: fieldVal(f),
      public: false,
    })),
  }
}

// Component that converts a list of fields to a list of components.
// When in development ENV, the forms with a formName have 2 secret powers:
// - window[formName].fill() fills the form fields to help testing.
// - console.log(window[formName].json) prints a JSON that can be used to create a channel in EVAG.
export default function FieldsToComps({
  fields,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  formName,
}) {
  if (process.env.NODE_ENV === "development" && formName) {
    window[formName] = {
      fill: () => fillFields(fields, setFieldValue),
      json: JSON.stringify(fieldsToJSON(fields, formName)),
    }
  }
  return (
    <>
      {fields.map(({ extra = {}, ...f }, i) => {
        if (extra.condition && !extra.condition({ values })) {
          return null
        }

        let defaultWidget = InputText
        if (f.options) {
          defaultWidget = Select
        }
        let Widget = extra.widget || defaultWidget

        if (Widget === InputText && !f.type) {
          f.type = "text"
        }

        if (Widget === InputAddress) {
          f.setFieldValue = setFieldValue
          f.handleChange = handleChange
          f.values = values
        } else {
          f.value = values[f.name]
          f.onChange = f.onChange || handleChange
        }

        return <Widget key={f.name || i} onBlur={handleBlur} {...f} />
      })}
    </>
  )
}
