import React from "react"
import PropTypes from "prop-types"

import s from "./form.module.scss"

const InputCheckbox = ({ className, label, value, ...props }) => {
  const cls = s.checkbox + (className ? ` ${className}` : "")
  return (
    <div className={s.container}>
      <label className={cls}>
        <input type="checkbox" checked={value} {...props} />
        <span>{label}</span>
      </label>
    </div>
  )
}

InputCheckbox.defaultProps = {
  className: "",
  label: "",
}

InputCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
}

export default InputCheckbox
