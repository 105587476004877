import i18n from "i18next"
import { initReactI18next } from "react-i18next"

var transCtx = require.context("src/locales", true, /\.json$/)
const translations = {}
for (let k of transCtx.keys()) {
  translations[k.split("/")[1].split(".")[0]] = { translation: transCtx(k) }
}

export const languages = {}
for (let [k, v] of Object.entries(translations)) {
  languages[k] = v.translation.lang
}

export function changeLangIfNeeded(i18n, lang) {
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang)
  }
}

// import Backend from "i18next-xhr-backend"
// import LanguageDetector from "i18next-browser-languagedetector"
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    resources: translations,
    lng: "pt",
    fallbackLng: "pt",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
