import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import siteMetadata from "src/queries/siteMetadata"

const SEO = function({ desc, lang, meta, keywords, title }) {
  const { url, description } = siteMetadata()
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = desc || description
        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            meta={[
              {
                name: "description",
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
              {
                property: "og:image",
                content: `${url}${data.pic.childImageSharp.fixed.src}`,
              },
              {
                property: "og:image:width",
                content: data.pic.childImageSharp.fixed.width,
              },
              {
                property: "og:image:height",
                content: data.pic.childImageSharp.fixed.height,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: "pt",
  meta: [],
  keywords: [],
  ogimage: null,
}

SEO.propTypes = {
  desc: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  ogimage: PropTypes.object,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    pic: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          src
          width
          height
        }
      }
    }
  }
`
