import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "src/style.scss"
import { useTranslation } from "react-i18next"
import logo from "src/images/logo_verde.png"
import s from "./Layout.module.scss"

const Layout = ({ className, children }) => {
  const { i18n } = useTranslation()
  return (
    <div
      className={s.container + (className ? " " + className : "")}
      dir={["ar"].includes(i18n.language) ? "rtl" : "ltr"}
    >
      <Link to={"/"}>
        <img
          className={s.logo}
          src={logo}
          alt="Rede Emancipa - movimento social de educação popular"
        />
      </Link>
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
