import React from "react"
import PropTypes from "prop-types"
import { ErrorMessage } from "formik"
import Cleave from "cleave.js/react"
import { useTranslation } from "react-i18next"

import s from "./form.module.scss"

const InputText = React.forwardRef(
  ({ className, cleave, label, ...props }, ref) => {
    const cls = s.text + (className ? ` ${className}` : "")
    const { t } = useTranslation()

    props.className = cls
    if (cleave) {
      props.options = cleave
      if (ref) {
        props.htmlRef = ref
      }
    } else {
      if (ref) {
        props.ref = ref
      }
    }

    return (
      <div className={s.container}>
        <label>
          {t(label)}
          {cleave ? <Cleave {...props} /> : <input {...props} />}
          <ErrorMessage name={props.name} component="div" className={s.error} />
        </label>
      </div>
    )
  }
)

InputText.defaultProps = {
  className: "",
  label: "",
  cleave: null,
  type: "text",
  width: "100%",
}

InputText.propTypes = {
  className: PropTypes.string,
  cleave: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
}

export default InputText
